import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'

class BooksIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location} pageName={'books'} >
        <div style={{ background: '#fff' }}>
          <Helmet title={'Books | ' + siteTitle} defer={false}/>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Courses</h1>
            <h2 className="section-list">Upcoming</h2>
            <h3>30 Minute Project - Become a better developer in just 30 minutes a day (Launching November)</h3>

            <h1 className="section-headline">Books</h1>
            <h2 className="section-list">Upcoming</h2>
            <h3>Pragmatic Node.js Testing (Coming Soon)</h3>
            <p>So, you've read a few tutorials, and you probably fell into one of two camps:</p>
            <ul>
              <li>Yay! I got my tests working! It was kindof a pain but I did it! You probably felt pretty good too - you may have even found a bug or two you didn’t know was there beforehand.</li>
              <li>Or OMG! I can not get these tests to run properly! Or it says I have 0 code coverage on this function? That makes no sense! And you threw your arms up in defeat.</li>
            </ul>
            <h3>Pragmatic Node.js Refactoring (Coming Soon)</h3>
            <p>You've seen all the new goodies with ES2018 and ESNext but what are the best ways to apply those to your own code? Better yet how do you refactor your existing code to take advantage of the latest techniques and language additions?</p>
            <ul>
              <li>When to refactor your code.</li>
              <li>How to refactor your code and not break it.</li>
              <li>Which language features and techniques give you the most bang for your buck?</li>
            </ul>
            <h2 className="section-list">Released</h2>
            <h3><a href={"https://www.amazon.com/Tapestry-Paperback-Author-Warner-Onstine/dp/B00GSWITNY/ref=sr_1_8?dchild=1&keywords=Tapestry+101&qid=1591994036&s=books&sr=1-8"} target={"_new"}>Tapestry 101</a> (2008)</h3>
            <p>Tapestry 101 introduces readers to this framework. The focus of this book is to guide you through designing and implementing your application and providing information that you can improve on it with your own custom components.</p>
            <h3><a href={"https://www.amazon.com/Professional-JavaTM-Tools-Extreme-Programming/dp/0764556177#:~:text=The%20Extreme%20Programming%20(XP)%20methodology,Ant%2C%20JUnit%2C%20and%20Cactus."} target={"_new"}>Professional Java Tools for Extreme Programming</a> (2004)</h3>
            <p>The Extreme Programming (XP) methodology enables you to build and test enterprise systems quickly without sacrificing quality. In the last few years, open source developers have created or significantly improved a host of Java XP tools, from XDoclet, Maven, AntHill, and Eclipse to Ant, JUnit, and Cactus. This practical, code-intensive guide shows you how to put these tools to work ― and capitalize on the benefits of Extreme Programming.</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BooksIndex

export const pageQuery = graphql`
  query BooksQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "2qEj12mQqwmNULCm51nEjP"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
